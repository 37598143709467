@import '../../../styles/customMediaQueries.css';

.root {
  position: fixed;
  width: 100%;
  top: auto;
  z-index: 1;
  margin: 0;
  background-color: var(--marketplaceSecondaryColor);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  @media (--viewportSm){
    padding: 15px;
  }
}

.rootSearchPage {
  display: flex;
  flex-direction: column;
}

.title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 31px;
  color: var(--colorWhite);
  margin: 0;
  padding: 0;
}

.titleSearchPage {
  font-family: 'Open Sans';
  font-size: 20px;
  font-weight: 600;
  color: var(--colorWhite);
  margin: 0;
  padding: 0;

  display: flex;
  gap: 10px;
}

.progressBar {
  padding: 10px 0 0 0;
}

.percentage {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 31px;
  color: var(--colorWhite);
}

.percentageSearchPage {
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 600;
  color: var(--colorWhite);
  margin: 0;
  padding: 0;
}