@import '../../../styles/customMediaQueries.css';

.buttonLink {
  transition: var(--transitionStyleButton);
  background-color: transparent;
  color: var(--marketplaceSecondaryColor);
  border: 1px solid var(--marketplaceSecondaryColor);
  min-height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: var(--fontWeightSemiBold);
  font-size: 16px;
  line-height: 24px;
  font-family: 'Montserrat';
  margin-top: 20px;
  margin-bottom: 20px;

  &:hover {
    background-color: var(--marketplaceColor);
    text-decoration: none;
  }
  @media (--viewportSm){
    width: 100%;
  }

}

.closeIcon {
  width: 14px;
  height: 14px;
  margin-top: 5px;

  &:hover {
    cursor: pointer;
  }
}

.tipIcon {
  width: 45px;
  height: 45px;
  margin-top: 5px;
}

.horizontalWrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  @media (--viewportSm){ 
      max-width: fit-content; 
  }
} 
.tip {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: var(--marketplaceSecondaryColor);
  margin: 0;
}

.title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #ffffff;
  margin: 0;
}

.profileImage {
  width: 128px;
  height: 128px;
}

.name {
  font-family: 'Open Sans';
  font-style: normal;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #ffffff;
}

.infoWrapper {
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.56px;
  color: #ffffff;
  margin: 0px;
  gap: 20px;
}

.moreTips {
  color: var(--marketplaceSecondaryColor);
  text-align: center;
  font-family: 'Open Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;

  &:hover {
    cursor: pointer;
  }
}